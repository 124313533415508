const mainStyle = theme => ({
  appbar: {
    backdropFilter: 'blur(10px)',
  },
  toolbar: {
    display: 'flex',
  },
  homeButton: {
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : 'inherit',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
});

export default mainStyle;
