import React, { useState, useContext, Fragment } from 'react';

import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';

import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { useTranslation } from 'react-i18next';

import { LoginButton as FbLogin } from 'react-facebook';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import SvgIcon from '@mui/material/SvgIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import FacebookIcon from '@mui/icons-material/Facebook';

import { Icon } from '@iconify/react';

import globe2Outline from '@iconify/icons-eva/globe-2-outline';
import chevronDownOutline from '@iconify/icons-eva/chevron-down-outline';
import chevronRightOutline from '@iconify/icons-eva/chevron-right-outline';

import UserContext from '../../utils/UserContext.jsx';
import { can } from '../../utils/Utils.jsx';

import FogliaIcon from '../../components/FogliaIcon.jsx';

import { makeStyles } from '../../utils/Utils.jsx';

import sidebarStyle from './sidebarStyle.jsx';

import routes from '../../routes/routes.jsx';
import { useEffect } from 'react';

const useStyles = makeStyles()(sidebarStyle);

const LOGIN = gql`
  mutation Login (
    $username: String!
    $password: String!
  ) {
    login (
      input: {
        username: $username
        password: $password
      }
    ) {
      token
      user {
        id
        username
        avatar: mediaByAvatar {
          externalUrl
          small
        }
      }
    }
  }
`;

const LOGOUT = gql`
  mutation Logout {
    logout {
      success
    }
  }
`;

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      username
      avatar: mediaByAvatar {
        externalUrl
        small
      }
    }
  }
`;

const FETCH_OWN_ORGANIZATIONS = gql`
  query FetchOwnOrganizations {
    ownOrganizations (
      first: 5
    ) {
      edges {
        node {
          id
          slug
          name
          activeEvents (
            first: 5
            orderBy: SIGNUP_OPEN_PERIOD_ASC
          ) {
            edges {
              node {
                id
                name
                currentUserPermissions
                eventAgeGroups: eventAgeGroupsByEventId (
                  orderBy: AGE_GROUP_SCHOOL_CLASS_ASC
                ) {
                  edges {
                    node {
                      id
                      name
                      currentUserPermissions
                      eventTeams (
                        orderBy: NAME_ASC
                      ) {
                        edges {
                          node {
                            id
                            name
                            currentUserPermissions
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Lateral menu
 * @param {*} props 
 * @returns 
 */
const LateralMenu = props => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  const { userCan } = useContext(UserContext);

  const location = useLocation();

  return (
    <nav className={classes.lateralMenu}>
      {routes.map((prop, index) => {
        if (prop.redirect || !prop.sidebarName || prop.path === '/') {
          return null;
        }
        if (prop.permission && (!userCan(prop.permission))) {
          return null;
        }
        return (
          <NavLink
            to={prop.path.replace(/\/:.*$/, '')}
            className={classes.listLink}
            activeClassName="active"
            key={index}
          >
            <ListItem button selected={location.pathname.indexOf(prop.path.replace(/\/:.*$/, '')) === 0 ? true : false}>
              <ListItemIcon>{prop.icon}</ListItemIcon>
              <ListItemText primary={t(prop.sidebarName)} />
            </ListItem>
          </NavLink>
        );
      })}
    </nav>
  );
};

/**
 * Handles the rendering of the Teams
 * section in the user menu
 * @param {*} params 
 * @returns 
 */
const TeamsSection = ({
  teams,
  ageGroupId,
  ageGroupPermissions,
  onDrawerClose,
  ...rest
}) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  if (teams && teams.edges.length > 0) {
    return <TreeItem
      nodeId={`${ageGroupId}_teams`}
      label={t('Teams')}
    >
      {teams.edges.map(teamEdge => {
        const edit = can('EDIT_TEAM', ageGroupPermissions);
        const presences = can('READ_PRESENCE', teamEdge.node.currentUserPermissions);

        // check how many permissions the user has
        const permNum = [edit, presences].filter(el => el).length;

        if (permNum === 0) {
          return null;
        }

        if (permNum === 1) {
          let mainLink;

          if (edit) {
            mainLink = `/team/${teamEdge.node.id}/settings`;
          } else if (presences) {
            mainLink = `/team/${teamEdge.node.id}/presences`;
          }

          return <TreeItem
            key={teamEdge.node.id}
            nodeId={teamEdge.node.id}
            label={teamEdge.node.name}
            onClick={() => {
              navigate(mainLink);
              onDrawerClose();
            }}
          />
        }

        return <TreeItem key={teamEdge.node.id} nodeId={teamEdge.node.id} label={teamEdge.node.name}>
          {permNum > 1 && edit && <TreeItem
            nodeId={`${teamEdge.node.id}_settings`}
            label={t('Settings')}
            onClick={() => {
              navigate(`/team/${teamEdge.node.id}/settings`);
              onDrawerClose();
            }}
          />}
          {permNum > 1 && presences && <TreeItem
            nodeId={`${teamEdge.node.id}_presences`}
            label={t('Presences')}
            onClick={() => {
              navigate(`/team/${teamEdge.node.id}/presences`);
              onDrawerClose();
            }}
          />}
        </TreeItem>;
      })}
    </TreeItem>;
  }

  return null;
};

/**
 * Handles the rendering of the Age Group
 * section in the user menu
 * @param {*} params 
 * @returns 
 */
const AgeGroupsSection = ({
  ageGroups,
  eventId,
  permissions,
  onDrawerClose,
  ...rest
}) => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  if (ageGroups && ageGroups.edges.length > 0) {
    // get the content of the section
    const ageGroupsContent = ageGroups.edges.map(edge => {
      const canEdit = can('EDIT_AGE_GROUP', permissions);
      const pres = can('READ_PRESENCE', permissions);
      const hasTeams = edge.node.eventTeams && edge.node.eventTeams.edges.length > 0;

      if (!canEdit && !hasTeams) {
        return null;
      }

      return <TreeItem
        key={edge.node.id}
        nodeId={edge.node.id}
        label={edge.node.name}
      >
        {canEdit && <TreeItem
          nodeId={`${edge.node.id}_settings`}
          label={t('Settings')}
          onClick={() => {
            navigate(`/age-group/${edge.node.id}/settings`);
            onDrawerClose();
          }}
        />}
        {pres && <TreeItem
          nodeId={`${edge.node.id}_presences`}
          label={t('Presences')}
          onClick={() => {
            navigate(`/age-group/${edge.node.id}/presences`);
            onDrawerClose();
          }}
        />}
        <TeamsSection
          teams={edge.node.eventTeams}
          ageGroupId={edge.node.id}
          ageGroupPermissions={permissions}
          onDrawerClose={onDrawerClose}
        />
      </TreeItem>;
    });

    // if the content is empty do not show the section
    if (ageGroupsContent.filter(el => el).length === 0) {
      return null;
    }

    return <TreeItem
      nodeId={`${eventId}_age_groups`}
      label={t('Age groups')}
    >
      {ageGroupsContent}
    </TreeItem>;
  }

  return null;
};

/**
 * Sidebar element.
 * @param {*} props 
 */
const Sidebar = ({
  onDrawerClose,
  ...rest
}) => {

  const { classes } = useStyles();

  const { t, i18n } = useTranslation();

  const { user, putUser, clearUser } = useContext(UserContext);

  const navigate = useNavigate();

  const [loginOpen, setLoginOpen] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginMessage, setLoginMessage] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const [fetchOwnOrganizations, { loading: ownOrganizationsLoading, data: ownOrganizationsData, error: ownOrganizationsError }] = useLazyQuery(FETCH_OWN_ORGANIZATIONS);
  const [currentUser, { loading }] = useLazyQuery(CURRENT_USER);
  const [login, { client }] = useMutation(LOGIN);
  const [logout,] = useMutation(LOGOUT);

  // if the user is set (i.e. logged in) try retrieve the organizations
  useEffect(() => {
    if (user) {
      fetchOwnOrganizations();
    }
  }, [user, fetchOwnOrganizations]);

  // perform a login operation
  const performLogin = ({ provider, token }) => {
    setLoginLoading(true);
    // retrieves the JWT from the provider
    fetch(`${window._env_.LOGIN_URL}/${provider}?access_token=${encodeURIComponent(token)}`)
      .then(res => res.json())
      .then(async res => {
        if (res.token) {
          const token = res.token;

          // set the token for the current_user request
          await putUser({ token: token });

          // retrieve the current user
          currentUser()
            .then(res => {
              setLoginMessage('');
              setLoginOpen(false);
              const payload = res.data.currentUser;
              // set the user
              putUser({
                token: token,
                user: {
                  id: payload.id,
                  username: payload.username,
                  avatar: payload.avatar ? (payload.avatar.small ? payload.avatar.small : payload.avatar.externalUrl) : null,
                  permissions: payload.permissions ? payload.permissions : []
                }
              })
                .then(() => {
                  // refetch all active queries
                  client.resetStore();
                });
            })
            .catch(err => {
              setLoginMessage(err.message)
            });
        } else {
          setLoginMessage(res.message);
        }
      })
      .catch(err => {
        console.warn(err);
        setLoginMessage(err);
      })
      .finally(_ => setLoginLoading(false));
  };

  return <aside
    className={classes.sidebar}
    role="presentation"
  >
    <section className={classes.user}>
      <Avatar
        className={classes.avatar}
        alt={user ? user.username : null}
        src={user ? user.avatar : null}
      />
      {user && <span>
        <Button
          color="secondary"
          endIcon={<SvgIcon>
            <Icon icon={chevronRightOutline} />
          </SvgIcon>}
          className={classes.username}
          component={Link}
          to="/me"
          onClick={onDrawerClose}
        >
          @{user.username}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={_ => {
            // clear user data
            clearUser();

            // logout from the server
            // and refetch all active queries
            logout().then(() => client.resetStore()).catch(err => console.error(err));
          }}
        >
          {t('Logout')}
        </Button>
      </span>}
      {!user && <Button
        variant="outlined"
        color="primary"
        onClick={_ => setLoginOpen(true)}
      >
        {t('Login')}
      </Button>}
    </section>
    {user && <Fragment>
      <Divider />
      {ownOrganizationsLoading && <LinearProgress />}
      {ownOrganizationsError && <Typography align="center" variant="body2">
        {ownOrganizationsError.message}
      </Typography>}
      {ownOrganizationsData && <TreeView
        aria-label={t('Organizations')}
        className={classes.ownOrganizations}
        defaultCollapseIcon={<SvgIcon>
          <Icon icon={chevronDownOutline} />
        </SvgIcon>}
        defaultExpandIcon={<SvgIcon>
          <Icon icon={chevronRightOutline} />
        </SvgIcon>}
      >
        {ownOrganizationsData.ownOrganizations.edges.map(edge => <TreeItem
          key={edge.node.id}
          nodeId={edge.node.id}
          label={<Button
            component={Link}
            to={`/o/${edge.node.slug}`}
            className={classes.organizationButton}
            onClick={onDrawerClose}
          >
            {edge.node.name}
          </Button>}
        >
          {edge.node.activeEvents && edge.node.activeEvents.edges.map(edge => {
            const edit = can('EDIT_EVENT', edge.node.currentUserPermissions);
            const finalize = can('FINALIZE_SIGNUP', edge.node.currentUserPermissions) || can('EDIT_SIGNUP', edge.node.currentUserPermissions);
            const dash = can('READ_DASHBOARD', edge.node.currentUserPermissions);
            const quickData = can('READ_DATA', edge.node.currentUserPermissions);
            const mail = can('READ_MAIL', edge.node.currentUserPermissions);
            const messages = can('READ_MESSAGE', edge.node.currentUserPermissions);
            const comm = can('SEND_COMMUNICATIONS', edge.node.currentUserPermissions);
            const hasAgeGroups = edge.node.eventAgeGroups && edge.node.eventAgeGroups.edges.length > 0;

            // check wether the age group section has some content to be shown
            const hasAgeGroupsContent = hasAgeGroups && edge.node.eventAgeGroups.edges.map(edge => {
              const canEdit = can('EDIT_AGE_GROUP', edge.node.currentUserPermissions);
              const pres = can('READ_PRESENCE', edge.node.currentUserPermissions);
              const hasTeams = edge.node.eventTeams && edge.node.eventTeams.edges.length > 0;

              return canEdit || pres || hasTeams;
            }).filter(el => el).length > 0;

            // check how many permissions the user has
            const permNum = [edit, finalize, dash, quickData, mail, comm].filter(el => el).length;

            if (permNum === 0 && !hasAgeGroupsContent) {
              return null;
            }

            if (permNum === 1 && !hasAgeGroupsContent) {
              let mainLink;

              if (edit) {
                mainLink = `/event/${edge.node.id}/settings`;
              } else if (finalize) {
                mainLink = `/event/${edge.node.id}/finalize`;
              } else if (dash) {
                mainLink = `/event/${edge.node.id}/dashboard`;
              } else if (quickData) {
                mainLink = `/event/${edge.node.id}/data`;
              } else if (mail) {
                mainLink = `/event/${edge.node.id}/mails`;
              } else if (messages) {
                mainLink = `/event/${edge.node.id}/messages`;
              } else if (comm) {
                mainLink = `/event/${edge.node.id}/communications`;
              }

              return <TreeItem
                key={edge.node.id}
                nodeId={edge.node.id}
                label={edge.node.name}
                onClick={() => {
                  navigate(mainLink);
                  onDrawerClose();
                }}
              />
            }

            return <TreeItem key={edge.node.id} nodeId={edge.node.id} label={edge.node.name}>
              {permNum > 1 && dash && <TreeItem
                nodeId={`${edge.node.id}_dashboard`}
                label={t('Dashboard')}
                onClick={() => {
                  navigate(`/event/${edge.node.id}/dashboard`);
                  onDrawerClose();
                }}
              />}
              {permNum > 1 && edit && <TreeItem
                nodeId={`${edge.node.id}_settings`}
                label={t('Settings')}
                onClick={() => {
                  navigate(`/event/${edge.node.id}/settings`);
                  onDrawerClose();
                }}
              />}
              {permNum > 1 && finalize && <TreeItem
                nodeId={`${edge.node.id}_finalize`}
                label={t('Finalize')}
                onClick={() => {
                  navigate(`/event/${edge.node.id}/finalize`);
                  onDrawerClose();
                }}
              />}
              {permNum > 1 && quickData && <TreeItem
                nodeId={`${edge.node.id}_data`}
                label={t('Data')}
                onClick={() => {
                  navigate(`/event/${edge.node.id}/data`);
                  onDrawerClose();
                }}
              />}
              {permNum > 1 && mail && <TreeItem
                nodeId={`${edge.node.id}_mails`}
                label={t('Mails')}
                onClick={() => {
                  navigate(`/event/${edge.node.id}/mails`);
                  onDrawerClose();
                }}
              />}
              {permNum > 1 && mail && <TreeItem
                nodeId={`${edge.node.id}_messages`}
                label={t('Messages')}
                onClick={() => {
                  navigate(`/event/${edge.node.id}/messages`);
                  onDrawerClose();
                }}
              />}
              {permNum > 1 && comm && <TreeItem
                nodeId={`${edge.node.id}_communications`}
                label={t('Communications')}
                onClick={() => {
                  navigate(`/event/${edge.node.id}/communications`);
                  onDrawerClose();
                }}
              />}
              <AgeGroupsSection
                ageGroups={edge.node.eventAgeGroups}
                eventId={edge.node.id}
                permissions={edge.node.currentUserPermissions}
                onDrawerClose={onDrawerClose}
              />
            </TreeItem>;
          })}
        </TreeItem>)}
      </TreeView>}
    </Fragment>}
    <Divider />
    <LateralMenu />
    <section className={classes.sidebarCommands}>
      <Button
        aria-label={t('Language selector')}
        aria-controls="lang-menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
        id="languageSelector"
        className={classes.languageSelector}
      >
        <SvgIcon className={classes.languageIcon}>
          <Icon icon={globe2Outline} />
        </SvgIcon>
        <span className={classes.languageText}>
          {i18n.language}
        </span>
      </Button>
    </section>
    <Menu
      id="lang-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={_ => setAnchorEl(null)}
    >
      {[{ code: 'it', text: '🇮🇹 Italiano' }, { code: 'en', text: '🇺🇸 English' }].map(option => (
        <MenuItem
          key={option.code}
          selected={option.code === i18n.language}
          data-language={option.code}
          onClick={event => { i18n.changeLanguage(event.target.dataset.language); setAnchorEl(null); }}
        >
          {option.text}
        </MenuItem>
      ))}
    </Menu>
    <Dialog
      onClose={_ => setLoginOpen(false)}
      aria-labelledby="login-dialog-title"
      open={loginOpen}
      BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.9)' } }}
    >
      <FogliaIcon color="primary" className={classes.logoIcon} />
      <DialogTitle
        id="login-dialog-title"
        className={classes.loginTitle}
      >
        FOGLIA
      </DialogTitle>
      <section className={classes.socialLogin}>
        <FbLogin
          scope="email,public_profile"
          onSuccess={response => {
            performLogin({ provider: 'facebook-token', token: response.authResponse.accessToken, user_id: response.authResponse.userID });
          }}
          onError={error => {
            // check if vibration is supported
            if ('vibrate' in navigator) {
              // signal the error via a vibration
              navigator.vibrate(500);
            }
            console.warn(error);
          }}
          asChild={({ disabled, ...rest }) => <Button
            className={classes.fbLoginButton}
            //onClick={handleClick}
            startIcon={<FacebookIcon />}
            disabled={loginLoading || disabled}
            {...rest}
          >
            {t('Login with Facebook')}
          </Button>}
        >
        </FbLogin>
      </section>
      <Divider variant="middle">{t('or')}</Divider>
      <form
        className={classes.loginForm}
        onSubmit={e => {
          e.preventDefault();
          setLoginLoading(true);
          login({
            variables: {
              username: username,
              password: password,
            }
          })
            .then(res => {
              // set the user
              putUser({
                token: res.data.login.token,
                user: {
                  id: res.data.login.user.id,
                  username: res.data.login.user.username,
                  avatar: res.data.login.user.avatar ? (res.data.login.user.avatar.small ? res.data.login.user.avatar.small : res.data.login.user.avatar.externalUrl) : null,
                  permissions: res.data.login.user.permissions ? res.data.login.user.permissions : []
                }
              })
                .then(() => {
                  // refetch all active queries
                  client.resetStore();
                });

              // clear the fields
              setUsername('');
              setPassword('');

              // close the login form
              setLoginOpen(false);
            })
            .catch(err => {
              // check if vibration is supported
              if ('vibrate' in navigator) {
                // signal the error via a vibration
                navigator.vibrate(500);
              }
              setLoginMessage(err.message);
            })
            .finally(() => setLoginLoading(false));
        }}
      >
        <TextField
          name="username"
          autoComplete="username"
          fullWidth
          label={t('Username')}
          variant="outlined"
          value={username}
          onChange={event => setUsername(event.target.value)}
          className={classes.loginField}
          required
          disabled={loginLoading || loading}
        />
        <TextField
          type="password"
          name="password"
          autoComplete="current-password"
          fullWidth
          label={t('Password')}
          variant="outlined"
          value={password}
          onChange={event => setPassword(event.target.value)}
          className={classes.loginField}
          required
          disabled={loginLoading || loading}
        />
        {loginMessage ? <Typography variant="body2">{loginMessage}</Typography> : ''}
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className={classes.loginButton}
          disabled={loginLoading || loading}
        >
          {(loginLoading || loading) ? <CircularProgress
            size={10}
            status={'loading'}
          /> : t('Login')}
        </Button>
        <div className={classes.loginActions}>
          <Button
            color="primary"
            component={Link}
            to="/register"
            className={classes.loginActionButton}
            disabled={loginLoading || loading}
            onClick={() => {
              setLoginOpen(false);
              onDrawerClose();
            }}
          >
            {t('Register')}
          </Button>
          <Button
            color="primary"
            component={Link}
            to="/forgot"
            className={classes.loginActionButton}
            disabled={loginLoading || loading}
            onClick={() => {
              setLoginOpen(false);
              onDrawerClose();
            }}
          >
            {t('Forgot password')}
          </Button>
        </div>
      </form>
    </Dialog>
  </aside>;
}

export default Sidebar;