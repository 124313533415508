import React, { Suspense, useMemo } from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';

import OpenReplay from '@openreplay/tracker';
import trackerGraphQL from '@openreplay/tracker-graphql';

import { onCLS, onFID, onLCP, onFCP } from 'web-vitals';

import {
  ApolloProvider,
  ApolloClient,
  ApolloLink,
  Observable,
  InMemoryCache
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { initDB, useIndexedDB } from 'react-indexed-db';

import { FacebookProvider } from 'react-facebook';

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import themeDefinitions from './assets/theme/theme';

import CircularProgress from '@mui/material/CircularProgress';

import ErrorBoundary from './utils/ErrorBoundary.jsx';
import { UserProvider } from './utils/UserContext.jsx';
import { OpenReplayProvider } from './utils/OpenReplayContext';

import indexedDB from './indexedDB.js';

import Main from './layouts/Main/Main';

//import * as serviceWorker from './serviceWorker';

// browser history
const hist = createBrowserHistory();

// matomo analytics
const matomoInstance = createInstance({
  urlBase: window._env_.MATOMO_URL,
  siteId: window._env_.MATOMO_SITE_ID,
  configurations: {
    disableCookies: true,
  }
});

// track page on first load
matomoInstance.trackPageView();

// send another pageview if the page is restored from bfcache
// TODO: check if it is really necessary - seems that triggers twice
window.addEventListener('pageshow', (event) => {
  if (event.persisted === true) {
    matomoInstance.trackPageView();
  }
});

// web vitals
const trackWebVital = ({ name, delta, id }) => {
  matomoInstance.trackEvent({
    category: 'web-vitals',
    action: id,
    name: name, // optional
    value: Math.round(name === 'CLS' ? delta * 1000 : delta),
  });
}

onCLS(trackWebVital);
onFID(trackWebVital);
onLCP(trackWebVital);
onFCP(trackWebVital);

// OpenReplay tracker
const openReplay = new OpenReplay({
  projectKey: window._env_.OPENREPLAY_PROJECT_KEY,
  ingestPoint: window._env_.OPENREPLAY_INGEST_POINT,
  __DISABLE_SECURE_MODE: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
});

// track GraphQL queries in OpenReplay
const recordGraphQL = openReplay.use(trackerGraphQL());

openReplay.start({
  metadata: {
    version: window._env_.APP_VERSION,
  }
});

// initialize the indexedDB istance
initDB(indexedDB);

// apollo link
const link = createUploadLink({
  uri: window._env_.GRAPHQL_ENDPOINT,
});

// apollo link to translate errors
const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // if errors are fount translate the message
    if (response.errors) {
      response.errors = response.errors.map(err => ({ ...err, message: i18n.t(err.message) }));
    }
    return response;
  });
});

// apollo link to insert auth token into headers
const authLink = new ApolloLink((operation, forward) => {
  // access the user store
  const { getAll } = useIndexedDB('user');

  const request = async (operation) => {
    const users = await getAll();
    if (users.length > 0) {
      // add the authorization to the headers
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          authorization: `Bearer ${users[0].token}`,
        }
      }));
    }
  };

  return new Observable(observer => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  });
});

// apollo link to track GraphQL queries
const openReplayApolloLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const operationDefinition = operation.query.definitions[0];
    return recordGraphQL(
      operationDefinition.kind === 'OperationDefinition' ? operationDefinition.operation : 'unknown?',
      operation.operationName,
      operation.variables,
      result
    );
  });
});

// graphql client
const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      SignupPresence: {
        keyFields: ['signupId', 'presenceDay']
      }
    }
  }),
  link: errorLink.concat(openReplayApolloLink).concat(authLink).concat(link)
});

const muiCache = createCache({
  key: 'foglia',
  prepend: true,
});

// the main app component
const Foglia = _ => {
  const prefersLightMode = useMediaQuery('(prefers-color-scheme: light)');

  const theme = useMemo(
    _ => {
      // set the palette type based on user preference
      const themeDefinition = prefersLightMode ? themeDefinitions.lightThemeDefinition : themeDefinitions.darkThemeDefinition;
      //const themeDefinition = themeDefinitions.lightThemeDefinition;

      // return the theme
      return responsiveFontSizes(
        createTheme(themeDefinition),
        { factor: 4 }
      );
    },
    [prefersLightMode],
  );

  return <ApolloProvider client={client}>
    <I18nextProvider i18n={i18n}>
      <OpenReplayProvider value={{ openReplay: openReplay }}>
        <MatomoProvider value={matomoInstance}>
          <UserProvider>
            <CacheProvider value={muiCache}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <ErrorBoundary i18n={i18n} tracker={matomoInstance}>
                    <FacebookProvider appId={window._env_.FB_APP_ID} wait>
                      <Suspense fallback={<CircularProgress size={40} status={'loading'} style={{ position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)' }} />}>
                        <BrowserRouter history={hist} location={hist.location}>
                          <Main />
                        </BrowserRouter>
                      </Suspense>
                    </FacebookProvider>
                  </ErrorBoundary>
                </ThemeProvider>
              </StyledEngineProvider>
            </CacheProvider>
          </UserProvider>
        </MatomoProvider>
      </OpenReplayProvider>
    </I18nextProvider>
  </ApolloProvider>;
}

const root = createRoot(document.getElementById('foglia'));
root.render(
  <React.StrictMode>
    <Foglia />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
