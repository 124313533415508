const footerStyle = theme => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  privacyPolicy: {
    marginRight: theme.spacing(2),
  },
  loveIcon: {
    height: '0.75em',
    width: 'unset',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  link: {
    textDecoration: 'none',
  },
});

export default footerStyle;
