import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

/**
 * Foglia icon component.
 *
 * The component renders the Foglia icon
 *
 * @param {*} props 
 */
const FogliaIcon = ({
  ...rest
}) => {

  return <SvgIcon {...rest} viewBox="0 0 2834.65 2834.65">
    <path d="M782.31,617.2c-518.68,301-488.91,791.17-471,972.55,669.94-793.59,1672-755.1,1672-755.1S562.8,1321.9,145.59,2299c-32.95,77.14,154.6,177.45,197.4,86.25,127.75-271.73,305.76-475.53,305.76-475.53,262.64,97.74,717,212.27,1039-14.33,427.74-301,384-968.29,994.62-1293.19C2825,526.33,1485.5,209.14,782.31,617.2Z" />
  </SvgIcon>;
}

export default FogliaIcon;