import { lighten } from '@mui/material/styles';

// the main purple shade
const mainPurple = '#7635DC';
// the main orange shade
const secondaryOrange = '#F48C20';

// the grey shades
const greys = {
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

// components default props
const defaultProps = {
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
      fullWidth: true,
    },
  },
  MuiSwitch: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiRadio: {
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiChip: {
    defaultProps: {
      variant: 'outlined',
    },
  },
  MuiTreeItem: {
    styleOverrides: {
      content: {
        padding: '4px',
      },
    },
  },
  MuiStack: {
    styleOverrides: {
      root: {
        flexWrap: 'wrap',
      },
    },
  },
};

// light theme definition
const lightThemeDefinition = {
  baseColors: {
    primary: mainPurple,
    secondary: secondaryOrange,
  },
  palette: {
    mode: 'light',
    primary: {
      main: mainPurple,
    },
    secondary: {
      main: secondaryOrange,
    },
    grey: greys
  },
  shadows: [
    'none',
    'rgba(145, 158, 171, 0.2) 0px 2px 1px -1px, rgba(145, 158, 171, 0.14) 0px 1px 1px 0px, rgba(145, 158, 171, 0.12) 0px 1px 3px 0px',
    'rgba(145, 158, 171, 0.2) 0px 3px 1px -2px, rgba(145, 158, 171, 0.14) 0px 2px 2px 0px, rgba(145, 158, 171, 0.12) 0px 1px 5px 0px',
    'rgba(145, 158, 171, 0.2) 0px 3px 3px -2px, rgba(145, 158, 171, 0.14) 0px 3px 4px 0px, rgba(145, 158, 171, 0.12) 0px 1px 8px 0px',
    'rgba(145, 158, 171, 0.2) 0px 2px 4px -1px, rgba(145, 158, 171, 0.14) 0px 4px 5px 0px, rgba(145, 158, 171, 0.12) 0px 1px 10px 0px',
    'rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 5px 8px 0px, rgba(145, 158, 171, 0.12) 0px 1px 14px 0px',
    'rgba(145, 158, 171, 0.2) 0px 3px 5px -1px, rgba(145, 158, 171, 0.14) 0px 6px 10px 0px, rgba(145, 158, 171, 0.12) 0px 1px 18px 0px',
    'rgba(145, 158, 171, 0.2) 0px 4px 5px -2px, rgba(145, 158, 171, 0.14) 0px 7px 10px 1px, rgba(145, 158, 171, 0.12) 0px 2px 16px 1px',
    'rgba(145, 158, 171, 0.2) 0px 5px 5px -3px, rgba(145, 158, 171, 0.14) 0px 8px 10px 1px, rgba(145, 158, 171, 0.12) 0px 3px 14px 2px',
    'rgba(145, 158, 171, 0.2) 0px 5px 6px -3px, rgba(145, 158, 171, 0.14) 0px 9px 12px 1px, rgba(145, 158, 171, 0.12) 0px 3px 16px 2px',
    'rgba(145, 158, 171, 0.2) 0px 6px 6px -3px, rgba(145, 158, 171, 0.14) 0px 10px 14px 1px, rgba(145, 158, 171, 0.12) 0px 4px 18px 3px',
    'rgba(145, 158, 171, 0.2) 0px 6px 7px -4px, rgba(145, 158, 171, 0.14) 0px 11px 15px 1px, rgba(145, 158, 171, 0.12) 0px 4px 20px 3px',
    'rgba(145, 158, 171, 0.2) 0px 7px 8px -4px, rgba(145, 158, 171, 0.14) 0px 12px 17px 2px, rgba(145, 158, 171, 0.12) 0px 5px 22px 4px',
    'rgba(145, 158, 171, 0.2) 0px 7px 8px -4px, rgba(145, 158, 171, 0.14) 0px 13px 19px 2px, rgba(145, 158, 171, 0.12) 0px 5px 24px 4px',
    'rgba(145, 158, 171, 0.2) 0px 7px 9px -4px, rgba(145, 158, 171, 0.14) 0px 14px 21px 2px, rgba(145, 158, 171, 0.12) 0px 5px 26px 4px',
    'rgba(145, 158, 171, 0.2) 0px 8px 9px -5px, rgba(145, 158, 171, 0.14) 0px 15px 22px 2px, rgba(145, 158, 171, 0.12) 0px 6px 28px 5px',
    'rgba(145, 158, 171, 0.2) 0px 8px 10px -5px, rgba(145, 158, 171, 0.14) 0px 16px 24px 2px, rgba(145, 158, 171, 0.12) 0px 6px 30px 5px',
    'rgba(145, 158, 171, 0.2) 0px 8px 11px -5px, rgba(145, 158, 171, 0.14) 0px 17px 26px 2px, rgba(145, 158, 171, 0.12) 0px 6px 32px 5px',
    'rgba(145, 158, 171, 0.2) 0px 9px 11px -5px, rgba(145, 158, 171, 0.14) 0px 18px 28px 2px, rgba(145, 158, 171, 0.12) 0px 7px 34px 6px',
    'rgba(145, 158, 171, 0.2) 0px 9px 12px -6px, rgba(145, 158, 171, 0.14) 0px 19px 29px 2px, rgba(145, 158, 171, 0.12) 0px 7px 36px 6px',
    'rgba(145, 158, 171, 0.2) 0px 10px 13px -6px, rgba(145, 158, 171, 0.14) 0px 20px 31px 3px, rgba(145, 158, 171, 0.12) 0px 8px 38px 7px',
    'rgba(145, 158, 171, 0.2) 0px 10px 13px -6px, rgba(145, 158, 171, 0.14) 0px 21px 33px 3px, rgba(145, 158, 171, 0.12) 0px 8px 40px 7px',
    'rgba(145, 158, 171, 0.2) 0px 10px 14px -6px, rgba(145, 158, 171, 0.14) 0px 22px 35px 3px, rgba(145, 158, 171, 0.12) 0px 8px 42px 7px',
    'rgba(145, 158, 171, 0.2) 0px 11px 14px -7px, rgba(145, 158, 171, 0.14) 0px 23px 36px 3px, rgba(145, 158, 171, 0.12) 0px 9px 44px 8px',
    'rgba(145, 158, 171, 0.2) 0px 11px 15px -7px, rgba(145, 158, 171, 0.14) 0px 24px 38px 3px, rgba(145, 158, 171, 0.12) 0px 9px 46px 8px',
  ],
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily: 'Nunito',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        .leaflet-marker-icon.marker-cluster {
          background-color: ${lighten(mainPurple, 0.7)};
        }
        .leaflet-marker-icon.marker-cluster > div {
          background-color: ${lighten(mainPurple, 0.5)};
        }
        .ql-snow button:hover .ql-stroke {
          stroke: ${lighten(mainPurple, 0.5)} !important;
        }
        .ql-snow button:hover .ql-fill {
          fill: ${lighten(mainPurple, 0.5)} !important;
        }
        .ql-snow .ql-picker-label:hover,
        .ql-snow .ql-picker-item:hover,
        .ql-snow .ql-selected {
          color: ${lighten(mainPurple, 0.5)} !important;
        }
        .ql-editor p,
        .ql-editor ol,
        .ql-editor ul,
        .ql-editor pre,
        .ql-editor blockquote,
        .ql-editor h1,
        .ql-editor h2,
        .ql-editor h3,
        .ql-editor h4,
        .ql-editor h5,
        .ql-editor h6 {
          margin-bottom: 1em !important;
        }
        .ql-editor ol,
        .ql-editor ul {
          padding-bottom: 1em;
        }
        .react-grid-item.react-grid-placeholder {
          background: ${mainPurple} !important;
        }
      `,
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: mainPurple,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&[colorized=primary]': {
            background: lighten(mainPurple, 0.7),
          },
          '&[colorized=secondary]': {
            background: lighten(secondaryOrange, 0.7),
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          '&[colorized=primary]': {
            color: mainPurple,
          },
          '&[colorized=secondary]': {
            color: secondaryOrange,
          },
        },
      },
    },
    ...defaultProps
  },
};

// dark theme definition
const darkThemeDefinition = {
  baseColors: {
    primary: mainPurple,
    secondary: secondaryOrange,
  },
  palette: {
    mode: 'dark',
    primary: {
      main: lighten(mainPurple, 0.75),
    },
    secondary: {
      main: secondaryOrange,
    },
    background: {
      paper: '#212B36',
      default: '#161C24',
    },
    grey: greys
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily: 'Nunito',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        .leaflet-marker-icon.marker-cluster {
          background-color: ${lighten(mainPurple, 0.7)};
        }
        .leaflet-marker-icon.marker-cluster > div {
          background-color: ${lighten(mainPurple, 0.5)};
        }
        .ql-snow .ql-stroke {
          stroke: #FFFFFF !important;
        }
        .ql-snow .ql-fill {
          fill: #FFFFFF !important;
        }
        .ql-snow .ql-picker-label {
          color: #FFFFFF !important;
        }
        .ql-snow button:hover .ql-stroke {
          stroke: ${lighten(mainPurple, 0.5)} !important;
        }
        .ql-snow button:hover .ql-fill {
          fill: ${lighten(mainPurple, 0.5)} !important;
        }
        .ql-snow .ql-picker-label:hover,
        .ql-snow .ql-picker-item:hover,
        .ql-snow .ql-selected {
          color: ${lighten(mainPurple, 0.5)} !important;
        }
        .ql-editor p,
        .ql-editor ol,
        .ql-editor ul,
        .ql-editor pre,
        .ql-editor blockquote,
        .ql-editor h1,
        .ql-editor h2,
        .ql-editor h3,
        .ql-editor h4,
        .ql-editor h5,
        .ql-editor h6 {
          margin-bottom: 1em !important;
        }
        .ql-editor ol,
        .ql-editor ul {
          padding-bottom: 1em;
        }
        .react-grid-item.react-grid-placeholder {
          background: ${mainPurple} !important;
        }
      `,
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&[colorized=primary]': {
            background: lighten(mainPurple, 0.7),
          },
          '&[colorized=secondary]': {
            background: lighten(secondaryOrange, 0.7),
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          '&[colorized=primary]': {
            color: mainPurple,
          },
          '&[colorized=secondary]': {
            color: secondaryOrange,
          },
        },
      },
    },
    ...defaultProps
  },
};

// the theme definitions object
const themeDefinitions = { lightThemeDefinition, darkThemeDefinition };

export default themeDefinitions;