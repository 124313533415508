import React from 'react';

import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';

const Root = styled('div')({});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.tracker = props.tracker;
  }

  static getDerivedStateFromError(error) {
    // update the status to be rendered on next draw
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // track the error
    this.tracker.trackEvent({
      category: 'generic-errors',
      action: 'Generic error',
      name: `${error.name} - ${error.message}`, // the value, "value" is a number
    });
  }

  render() {
    // shows the error
    if (this.state.hasError) {
      return (
        <Root>
          <Typography variant="h1" align="center">
            {this.props.i18n.t('An error happened')}
          </Typography>
          <Typography variant="body1" align="center">
            {this.props.i18n.t('Try reload the page. If the error persists contact us.')}
          </Typography>
        </Root>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;