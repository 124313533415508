import { createContext } from 'react';

const OpenReplayContext = createContext();

const OpenReplayProvider = OpenReplayContext.Provider;

const OpenReplayConsumer = OpenReplayContext.Consumer;

export default OpenReplayContext;

export { OpenReplayContext, OpenReplayProvider, OpenReplayConsumer };