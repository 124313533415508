import React, { useState, useEffect, useContext, Fragment } from 'react';

import { Routes as Routing, Route, Link as RouterLink, useLocation } from 'react-router-dom';

import { useMatomo } from '@jonkoops/matomo-tracker-react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import SvgIcon from '@mui/material/SvgIcon';
import { Icon } from '@iconify/react';

import menuFill from '@iconify/icons-eva/menu-fill';

import UserContext from '../../utils/UserContext.jsx';

import FogliaIcon from '../../components/FogliaIcon.jsx';

import { makeStyles } from '../../utils/Utils.jsx';

import mainStyle from './mainStyle.jsx';

import Sidebar from './Sidebar.jsx';
import Footer from './Footer.jsx';

import routes from '../../routes/routes.jsx';

const useStyles = makeStyles()(mainStyle);

// appbar hides on scoll
const HideOnScroll = props => {
  const { children } = props;
  const trigger = useScrollTrigger();

  return <Slide appear={false} direction="down" in={!trigger}>
    {children}
  </Slide>;
}

/**
 * Routes renderer
 * @param {*} props 
 * @returns 
 */
const Routes = props => {
  const { userCan } = useContext(UserContext);
  return <Routing>
    {routes.map((prop, key) => {
      if (prop.permission && (!userCan(prop.permission))) {
        return null;
      }
      return <Route path={prop.path} exact={prop.exact ? true : undefined} element={<prop.component />} key={key} />;
    })}
  </Routing>
};

/**
 * Main app element.
 * Contains the appbar, the main menu and the footer.
 * @param {*} props 
 */
const Main = props => {

  const { classes } = useStyles();

  const { trackPageView } = useMatomo();
  const location = useLocation();

  useEffect(() => {
    // listen for changes in the url and track
    trackPageView();
  }, [location, trackPageView]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  // toggle the drawer opening
  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    // clicks from the language selector do not close the drawer
    if (event.target.closest('#languageSelector')) {
      return;
    }
    setDrawerOpen(open);
  };

  return <Fragment>
    <CssBaseline enableColorScheme />
    <HideOnScroll>
      <AppBar
        color="transparent"
        elevation={0}
        className={classes.appbar}
      >
        <Toolbar className={classes.toolbar}>
          <RouterLink to="/" className={classes.homeButton}>
            <FogliaIcon />
            <strong>FOGLIA</strong>
          </RouterLink>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            size="large">
            <SvgIcon>
              <Icon icon={menuFill} />
            </SvgIcon>
          </IconButton>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
    <Toolbar />
    <SwipeableDrawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={_ => setDrawerOpen(true)}
    >
      <Sidebar onDrawerClose={_ => setDrawerOpen(false)} />
    </SwipeableDrawer>
    <Routes />
    <Footer />
  </Fragment>;
}

export default Main;