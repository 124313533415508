import React, { createContext, useContext, useState, useEffect } from 'react';

import { useIndexedDB } from 'react-indexed-db';

import { useMatomo } from '@jonkoops/matomo-tracker-react';

import OpenReplayContext from './OpenReplayContext';

const UserContext = createContext();

/**
 * Provide the informations about the user
 *
 * @param {*} props 
 * @returns Context provider
 */
const UserProvider = props => {

  const { getAll, add, clear } = useIndexedDB('user');

  const { pushInstruction } = useMatomo();
  const { openReplay } = useContext(OpenReplayContext);

  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  // retrieve the user from the local db
  const fetchUser = _ => {
    // check if the user is set
    getAll().then(users => {
      if (users.length > 0) {
        setUser(users[0].user);
        setToken(users[0].token);
        // set Matomo user
        pushInstruction('setUserId', users[0].user.id);
        // set OpenReplay user
        openReplay.setUserID(users[0].user.id);
      } else {
        setUser(null);
        setToken(null);
        // clear Matomo user
        pushInstruction('setUserId', undefined);
        // clear OpenReplay user
        openReplay.setUserID(undefined);
      }
    }).catch(error => { });
  };

  // add the user to the local db
  const putUser = async usr => {
    await clear();

    // add the user to the local store
    add({ ...usr, key: 'user' })
      .then(data => {
        fetchUser();
      })
      .catch(error => {
        console.error(error);
      });
  };

  // clear user data
  const clearUser = _ => {
    clear()
      .then(res => {
        fetchUser();
      })
      .catch(err => console.warn(err));
  };

  // check if the user has a permission
  const userCan = perm => {
    return user && user.permissions.indexOf(perm) > -1;
  };

  // mount
  // eslint-disable-next-line
  useEffect(_ => fetchUser(), []);

  return <UserContext.Provider value={{ user, token, putUser, clearUser, userCan }}>
    {props.children}
  </UserContext.Provider>;
};

const UserConsumer = UserContext.Consumer;

export default UserContext;

export { UserContext, UserProvider, UserConsumer };