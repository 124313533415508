import React from 'react';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link/Link';

import Iubenda from 'react-iubenda-policy';

import FavoriteIcon from '@mui/icons-material/Favorite';

import { makeStyles } from '../../utils/Utils.jsx';

import footerStyle from './footerStyle.jsx';

const useStyles = makeStyles()(footerStyle);

/**
 * Footer element.
 * @param {*} props 
 */
const Footer = props => {

  const { classes } = useStyles();

  return <footer className={classes.footer}>
    <span className={classes.privacyPolicy}>
      <Iubenda id={window._env_.IUBENDA_APP_ID} />
    </span>
    <Typography variant="body2">made with&nbsp;</Typography>
    <FavoriteIcon className={classes.loveIcon} />
    <Typography variant="body2">&nbsp;by <Link
      href="https://buzteve.dev"
      target="_blank"
      rel="author noopener"
      underline="none"
      className={classes.link}
    >
      @buzteve
    </Link>
    </Typography>
  </footer>;
}

export default Footer;