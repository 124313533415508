import React from 'react';
import loadable from '@loadable/component';

import CircularProgress from '@mui/material/CircularProgress';

/**
 * Create a fallback loader
 * @param {*} param 
 * @returns 
 */
const Fallback = ({
  ...rest
}) => {
  return <CircularProgress
    size={40}
    status={'loading'}
    style={{ margin: '45vh calc( 50% - 20px )' }}
  />;
};

const routes = [
  { // home shows the most interesting things :)
    path: '/',
    exact: true,
    component: loadable(() => import('../views/ActiveEvents/ActiveEvents.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // privacy
    path: '/privacy',
    exact: true,
    component: loadable(() => import('../views/Privacy/Privacy.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // register
    path: '/register',
    exact: true,
    component: loadable(() => import('../views/Register/Register.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // verify email
    path: '/verify',
    exact: true,
    component: loadable(() => import('../views/VerifyEmail/VerifyEmail.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // forgot password
    path: '/forgot',
    exact: true,
    component: loadable(() => import('../views/ForgotPassword/ForgotPassword.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // reset password
    path: '/reset',
    exact: true,
    component: loadable(() => import('../views/ResetPassword/ResetPassword.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // current user
    path: '/me',
    exact: true,
    component: loadable(() => import('../views/CurrentUser/CurrentUser.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // create organization
    path: '/create-organization',
    exact: true,
    component: loadable(() => import('../views/CreateOrganization/CreateOrganization.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // organization
    path: '/o/:slug',
    exact: true,
    component: loadable(() => import('../views/Organization/Organization.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // create event
    path: '/o/:organizationId/create-event',
    exact: true,
    component: loadable(() => import('../views/CreateEvent/CreateEvent.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // organization events
    path: '/o/:organizationId/events',
    exact: true,
    component: loadable(() => import('../views/Events/Events.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event settings
    path: '/event/:eventId/settings',
    exact: true,
    component: loadable(() => import('../views/EventSettings/EventSettings.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event age groups
    path: '/event/:eventId/age-groups',
    exact: true,
    component: loadable(() => import('../views/EventAgeGroups/EventAgeGroups.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event age group settings
    path: '/age-group/:eventAgeGroupId/settings',
    exact: true,
    component: loadable(() => import('../views/EventAgeGroupSettings/EventAgeGroupSettings.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event teams
    path: '/age-group/:eventAgeGroupId/teams',
    exact: true,
    component: loadable(() => import('../views/EventTeams/EventTeams.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event age group presences
    path: '/age-group/:eventAgeGroupId/presences',
    exact: true,
    component: loadable(() => import('../views/EventAgeGroupPresences/EventAgeGroupPresences.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event team settings
    path: '/team/:teamId/settings',
    exact: true,
    component: loadable(() => import('../views/EventTeamSettings/EventTeamSettings.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event team presences
    path: '/team/:teamId/presences',
    exact: true,
    component: loadable(() => import('../views/EventTeamPresences/EventTeamPresences.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event roles
    path: '/event/:eventId/roles',
    exact: true,
    component: loadable(() => import('../views/EventRoles/EventRoles.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event data
    path: '/event/:eventId/data',
    exact: true,
    component: loadable(() => import('../views/EventData/EventData.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event dashboard
    path: '/event/:eventId/dashboard',
    exact: true,
    component: loadable(() => import('../views/EventDashboard/EventDashboard.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event mails
    path: '/event/:eventId/mails',
    exact: true,
    component: loadable(() => import('../views/Mails/Mails.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event mail details
    path: '/event/:eventId/mails/:masterId',
    exact: true,
    component: loadable(() => import('../views/MailDetails/MailDetails.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event messages
    path: '/event/:eventId/messages',
    exact: true,
    component: loadable(() => import('../views/Messages/Messages.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event message details
    path: '/event/:eventId/messages/:masterId',
    exact: true,
    component: loadable(() => import('../views/MessageDetails/MessageDetails.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // event communications
    path: '/event/:eventId/communications',
    exact: true,
    component: loadable(() => import('../views/EventCommunications/EventCommunications.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // finalize signups
    path: '/event/:eventId/finalize',
    exact: true,
    component: loadable(() => import('../views/Finalize/Finalize.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // finalize signup
    path: '/event/:eventId/finalize/:progressiveId',
    exact: true,
    component: loadable(() => import('../views/FinalizeSignup/FinalizeSignup.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // signup
    path: '/event/:eventId/signup',
    exact: true,
    component: loadable(() => import('../views/Signup/Signup.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // signup detail
    path: '/s/:signupId',
    exact: true,
    component: loadable(() => import('../views/SignupDetail/SignupDetail.jsx'), {
      fallback: <Fallback />
    }),
  },
  { // 404
    path: '*',
    component: loadable(() => import('../views/FourZeroFour/FourZeroFour.jsx'), {
      fallback: <Fallback />
    }),
  },
];

export default routes;