const sidebarStyle = theme => ({
  sidebar: {
    minWidth: '20em',
    maxWidth: '90vw',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  user: {
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  username: {
    display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(0.5),
    justifyContent: 'space-between',
  },
  logoIcon: {
    margin: `${theme.spacing(2)} auto`,
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  loginTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  socialLogin: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  loginForm: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  loginField: {
    marginBottom: theme.spacing(1),
  },
  fbLoginButton: {
    display: 'flex',
    margin: `${theme.spacing(2)} auto`,
    width: '100%',
    color: theme.palette.getContrastText('#3B5998'),
    backgroundColor: '#3B5998',
    '&:hover': {
      color: theme.palette.getContrastText('#324980'),
      backgroundColor: '#324980',
    },
  },
  loginButton: {
    display: 'block',
    margin: `${theme.spacing(2)} auto`,
  },
  loginActions: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginActionButton: {
    margin: theme.spacing(0.5),
    //width: 'fit-content',
  },
  lateralMenu: {
    flex: 1,
  },
  ownOrganizations: {
    overflow: 'auto',
  },
  organizationButton: {
    width: '100%',
    justifyContent: 'start',
  },
  listLink: {
    textDecoration: 'none',
    '&, &:hover': {
      color: theme.palette.text.primary,
    }
  },
  languageIcon: {
    marginRight: theme.spacing(1),
  },
  sidebarCommands: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export default sidebarStyle;
